import { CrudModel } from "../CrudModel";
import {
  IRelationshipPropertyMany,
  RelationshipPropertyMany,
} from "./RelationshipPropertyMany";

export interface IMultipleFileProperty extends IRelationshipPropertyMany {
  fileProperty: string;
  apiUploadProperty?: string;
  basenameProperty?: string;
}
export class MultipleFileProperty extends RelationshipPropertyMany {
  public serializedValueGuarded(visited = new WeakSet<any>()): any {
    return this.uploading ? this.uploadValue : this.value;
  }

  public get serializedName() {
    return this.uploading ? this.apiUploadProperty : super.serializedName;
  }

  protected uploadValue: File[] = [];
  protected _apiUploadProperty: string = "";
  public basenameProperty: string = "basename";
  public fileProperty: string = "";
  public get apiUploadProperty() {
    return this._apiUploadProperty
      ? this._apiUploadProperty
      : this.serializedName;
  }

  protected get uploading() {
    return this.uploadValue.length > 0;
  }

  public setUpload(value) {
    this.uploadValue = value;
    this._hasUnsavedChanges = true;
    this._isHydrated = true;
  }

  constructor(opts: IMultipleFileProperty, model: CrudModel) {
    super(opts, model);

    if (!opts.fileProperty)
      throw new Error(
        "fileProperty is required for MultipleFileProperty definition"
      );

    this.fileProperty = opts.fileProperty;

    if (typeof opts.apiUploadProperty !== "undefined")
      this._apiUploadProperty = opts.apiUploadProperty;

    if (typeof opts.basenameProperty !== "undefined")
      this.basenameProperty = opts.basenameProperty;
  }

  public get isEmpty() {
    return !this.uploading && this.collection.isEmpty;
  }
}
