import { CrudModel } from "./CrudModel";
import { IReactiveValue, ReactiveValue } from "./ReactiveValue";
import Rule, { IUseRule } from "./Rule";
import {
  HasUserPermissions,
  PermissableEntity,
  UserPermission,
  UserPermissions,
} from "./UserPermission";

export type CrudPropertyType = typeof CrudProperty;
export type ICrudPropertyType = CrudPropertyType | string;

export interface ICrudPropertyGet {
  decorated: boolean;
  formatted: boolean;
  decoratorSuffix?: string;
  decoratorPrefix?: string;
}
export type CrudPropertyGet = ICrudPropertyGet | boolean;

export interface ICrudPropertyDefinition {
  type: ICrudPropertyType;
  opts?: ICrudProperty;
}
export interface ICrudProperty {
  name?: string;
  label?: string;
  serializedName?: string;
  serializedChangesName?: string;
  typedName?: string;
  rules?: IUseRule[];
  default?: any;
  computed?: boolean; // deprecated
  isRemotelyComputed?: boolean;
  description?: string;
  sortable?: boolean;
  decoratorPrefix?: string;
  decoratorSuffix?: string;
  decorator?: (val: any) => any;
  userPermissions?: UserPermissions | UserPermission;
  reactiveValue?: IReactiveValue;
}

export type CrudPropertyQuery = CrudProperty | string;

export interface HasProperties {
  findProperty(
    propertyQuery: CrudPropertyQuery,
    optionalResult?: boolean
  ): CrudProperty | undefined;
  findProperties(
    propertyQuery?: CrudPropertyQuery[] | CrudPropertyQuery
  ): CrudProperty[] | undefined;
}

export interface IPropertyFindInstanceArgs {
  prop?: string | CrudProperty | ICrudProperty;
  type?: ICrudPropertyType;
  model?: CrudModel;
}

export type ValueSubscriberCallback = (value: any) => any;
export class CrudProperty implements HasProperties, HasUserPermissions {
  private static _crudModelPropertyTypes: Record<string, ICrudPropertyType> =
    {};
  public static getCrudPropertyType(type: ICrudPropertyType): CrudPropertyType {
    if (typeof type !== "string") return type as CrudPropertyType;
    if (!this._crudModelPropertyTypes[type])
      throw new Error("CrudPropertyType not registered: " + type);

    return this._crudModelPropertyTypes[type] as CrudPropertyType;
  }
  public static registerCrudPropertyType(
    name: string,
    type: ICrudPropertyType
  ) {
    this._crudModelPropertyTypes[name] = type;
  }
  public static registerCrudPropertyTypes(
    registrationMap: Record<string, ICrudPropertyType> = {}
  ) {
    Object.keys(registrationMap).forEach((name: string) =>
      this.registerCrudPropertyType(
        name,
        registrationMap[name] as CrudPropertyType
      )
    );
  }
  public isOfType(typeArg: ICrudPropertyType) {
    const type = this._classRef.getCrudPropertyType(typeArg);
    return this._classRef === type;
  }
  public static isOfType(typeArg: ICrudPropertyType) {
    const type = this.getCrudPropertyType(typeArg);
    return this === type;
  }

  // factory method
  private static anonymousInstanceCount = 0;
  public static newInstance(
    def: ICrudPropertyDefinition,
    model?: CrudModel
  ): CrudProperty {
    return new (this.getCrudPropertyType(def.type))(
      def.opts ? def.opts : {},
      model
    );
  }
  public static newInstances(
    defs: ICrudPropertyDefinition[] = [],
    model?: CrudModel
  ) {
    return defs.map((fieldDef) => this.newInstance(fieldDef, model));
  }

  // find or create Instance
  public static findOrCreateInstance(args: IPropertyFindInstanceArgs) {
    if (args.prop && args.prop instanceof CrudProperty) return args.prop;

    const lookingUpPropertyByName = typeof args.prop === "string";
    if (lookingUpPropertyByName) {
      if (args.model) {
        const modelProp = args.model.findProperty(args.prop as string);
        if (modelProp) return modelProp;

        console.info(
          'Unable to find property "' +
            args.prop +
            '" on model, generating property.',
          args.model
        );

        if (!args.type) {
          console.error(args);
          throw new Error("Not enough arguments to findInstance: ");
        }

        return this.newInstance(
          {
            type: args.type,
            opts: {
              name: args.prop as string,
            },
          },
          args.model
        );
      }
    }

    if (!args.type) {
      console.error(args);
      throw new Error("Not enough arguments to findInstance: ");
    }

    // generate anonymous property
    const newInstanceArgs: ICrudPropertyDefinition = { type: args.type };

    // property val is ICrudProperty
    if (typeof args.prop === "object")
      newInstanceArgs.opts = args.prop as ICrudProperty;

    return this.newInstance(newInstanceArgs, args.model);
  }

  protected _userPermissions?: UserPermissions;
  public get userPermissions(): UserPermissions {
    if (typeof this._userPermissions === "undefined")
      this._userPermissions = this.model
        ? this.model.getPermissions()
        : new UserPermissions({}, UserPermission.Edit);

    return this._userPermissions;
  }
  public set userPermissions(value) {
    this._userPermissions = value;
  }

  public isVisibleToUser(user?, model?: CrudModel) {
    if (model && model.isNew && this.isRemotelyComputed) return false;

    if (!model) model = this.model;

    if (!user && this.$nuxt.$auth.loggedIn) user = this.$nuxt.$auth.user;
    return this.userPermissions.isVisibleToUser(model, user);
  }

  protected _isReadonly = false;
  public get isReadonly() {
    return this._isReadonly || this.reactiveValue?.lockProperty;
  }

  public isReadonlyToUser(user?, model?: PermissableEntity) {
    if (!user && this.$nuxt.$auth.loggedIn) user = this.$nuxt.$auth.user;
    if (this.isRemotelyComputed || this._isReadonly) return true;
    return this.userPermissions.isReadonlyToUser(this.model, user);
  }

  protected _default: any;

  public get hasDefault(): boolean {
    return typeof this._default !== "undefined";
  }

  public get default(): any {
    if (!this.hasDefault) return null;
    if (typeof this._default === "function") return this._default(this);
    return this._default;
  }

  public description: string | null = "";

  public maybeSetDefault() {
    if (this.hasDefault && this.default !== null && this.value === null) {
      this.set(this.default);
      this._isTouched = false;
    }
  }

  public name: string;
  public label: string;
  protected _value: any = null;
  public get value(): any {
    if (this.reactiveValue?.isEnabled) {
      // mark as an unsaved changed if this value is different than _value
      if (this.compareValues(this.reactiveValue.value, this._value) !== 0) {
        this._hasUnsavedChanges = true;
        this._isHydrated = true;
      }

      return this.reactiveValue.value;
    }

    return this._value;
  }
  public set value(val: any) {
    this.set(val);
  }

  public rules: IUseRule[] = [];
  public sortable: boolean = false;
  public isRemotelyComputed: boolean = false;

  protected get _classRef(): CrudPropertyType {
    return Object.getPrototypeOf(this).constructor;
  }

  protected static $nuxt;
  public static setNuxtContext(context) {
    this.$nuxt = context;
  }
  protected get $nuxt() {
    return this._classRef.$nuxt;
  }

  protected _model: CrudModel | undefined;
  public get model(): CrudModel | undefined {
    return this._model;
  }

  protected reactiveValue: ReactiveValue | undefined;

  protected _opts: ICrudProperty;
  constructor(opts: ICrudProperty, model?: CrudModel) {
    this._opts = opts;

    if (typeof model !== "undefined") this._model = model;

    if (typeof opts.name !== "undefined") this.name = opts.name;
    else this.name = "_anonymous" + this._classRef.anonymousInstanceCount++;

    if (typeof opts.label !== "undefined") this.label = opts.label;
    else this.label = this.name[0].toUpperCase() + this.name.substring(1);

    if (typeof opts.serializedName !== "undefined")
      this._serializedName = opts.serializedName;

    if (typeof opts.serializedChangesName !== "undefined")
      this._serializedChangesName = opts.serializedChangesName;

    if (typeof opts.typedName !== "undefined") this._typedName = opts.typedName;

    if (typeof opts.sortable !== "undefined") this.sortable = opts.sortable;

    if (typeof opts.default !== "undefined") this._default = opts.default;

    if (typeof opts.rules !== "undefined") this.rules = opts.rules;

    if (typeof opts.computed !== "undefined")
      this.isRemotelyComputed = opts.computed;

    if (typeof opts.isRemotelyComputed !== "undefined")
      this.isRemotelyComputed = opts.isRemotelyComputed;

    if (typeof opts.description !== "undefined")
      this.description = opts.description;

    if (typeof opts.decorator !== "undefined") this.decorate = opts.decorator;

    if (typeof opts.userPermissions !== "undefined")
      this.userPermissions =
        typeof opts.userPermissions === "object"
          ? opts.userPermissions
          : new UserPermissions(opts.userPermissions);

    if (typeof opts.decoratorPrefix !== "undefined")
      this.decoratorPrefix = opts.decoratorPrefix;

    if (typeof opts.decoratorSuffix !== "undefined")
      this.decoratorSuffix = opts.decoratorSuffix;

    if (typeof opts.reactiveValue !== "undefined" && this.model)
      this.reactiveValue = ReactiveValue.newInstance(opts.reactiveValue, this);

    // we maybe set default if this property is an orphan. Otherwise, the model will set it.
    if (!this.model) this.maybeSetDefault();
  }

  protected _isHydrated = false;
  public get isHydrated(): boolean {
    return this.isHydratedGuarded();
  }

  public isHydratedGuarded(visited = new WeakSet<any>()): boolean {
    if (visited.has(this)) return false;
    visited.add(this);

    return this._isHydrated;
  }

  protected _hasUnsavedChanges = false;
  public get hasUnsavedChanges(): boolean {
    return this._hasUnsavedChanges;
  }

  public hasUnsavedChangesGuarded(visited = new WeakSet<any>()): boolean {
    return this.hasUnsavedChanges;
  }

  protected _isTouched = false;
  public get isTouched(): boolean {
    return this._isTouched;
  }

  public markAsTouched() {
    this._isTouched = true;
  }

  public markAsSaved() {
    // if we're using a reactive value and it's not what our _value is,
    // we don't want to mark as saved
    if (
      this.reactiveValue?.isEnabled &&
      this.compareTo(this.reactiveValue.value) !== 0
    ) {
      console.log(
        this.reactiveValue?.isEnabled,
        this.reactiveValue.value,
        this._value,
        this.compareTo(this.reactiveValue.value) !== 0
      );
      return;
    }

    // this._isTouched = false;
    this._hasUnsavedChanges = false;
  }

  protected decoratorPrefix: string = "";
  protected static decoratorPrefix: string = "";
  protected decoratorSuffix: string = "";
  protected static decoratorSuffix: string = "";
  protected decorate(val): string {
    return (
      this.decoratorPrefix +
      this._classRef.decoratorPrefix +
      val +
      this._classRef.decoratorSuffix +
      this.decoratorSuffix
    );
  }

  public get(opts?: CrudPropertyGet) {
    if (!opts) opts = false;
    if (typeof opts === "boolean")
      opts = {
        formatted: !opts,
        decorated: !opts,
      };

    let retVal = opts.formatted ? this.stringValue : this.value;

    if (this.value === null) return null;

    if (opts.decorated) retVal = this.decorate(retVal);

    return retVal;
  }

  public get isEmpty() {
    return this.value === null;
  }

  public set(val: unknown, skipMarkingAsUnsaved = false) {
    this._isHydrated = true;
    if (typeof val === "undefined") return;

    this._value = this.coerceValue(val);

    if (!skipMarkingAsUnsaved) {
      this._hasUnsavedChanges = true;
      this._isTouched = true;
    }
  }

  public coerceValue(val: unknown): any {
    if (val === null) return null;

    return val;
  }

  public compareValues(a: any, b: any): number {
    const aCoerced = this.coerceValue(a);
    const bCoerced = this.coerceValue(b);

    if (aCoerced === bCoerced) return 0;

    if (aCoerced === null || bCoerced === null) {
      return aCoerced === null ? -1 : 1;
    }

    return aCoerced < bCoerced ? -1 : 1;
  }

  public compareTo(b: any): number {
    return this.compareValues(this._value, b);
  }

  protected snapshots: { id: number; data: Record<string, any> }[] = [];
  public takeSnapshot(snapshotId?: number) {
    if (!snapshotId) snapshotId = Date.now();
    else if (this.hasSnapshot(snapshotId)) return snapshotId;

    this.snapshots.push({
      id: snapshotId,
      data: this.typedValue,
    });

    return snapshotId;
  }

  public hasSnapshot(snapshotId?: number) {
    if (!snapshotId) return this.snapshots.length > 0;
    return this.snapshots.some((snapshot) => snapshot.id == snapshotId);
  }

  public getSnapshot(snapshotId?: number) {
    const snapshot = snapshotId
      ? this.snapshots.find((snapshot) => snapshot.id == snapshotId)
      : this.snapshots[this.snapshots.length - 1];
    if (!snapshot) {
      console.error(this.snapshots);
      throw new Error("Snapshot not found: " + snapshotId);
    }

    return snapshot;
  }

  public restoreSnapshot(snapshotId?: number) {
    const snapshot = this.getSnapshot(snapshotId);

    this.set(snapshot.data, true);
    this.markAsSaved();
  }

  public toPlainObject(opts?: CrudPropertyGet, model?: CrudModel) {
    return this.get(opts);
  }

  protected _serializedName: string | undefined;
  public get serializedName() {
    return this._serializedName ? this._serializedName : this.name;
  }

  protected _serializedChangesName: string | undefined;
  public get serializedChangesName() {
    return this._serializedChangesName
      ? this._serializedChangesName
      : this.serializedName;
  }

  protected _typedName: string | undefined;
  public get typedName() {
    return this._typedName ? this._typedName : this.serializedName;
  }

  public get serializedValue() {
    return this.serializedValueGuarded();
  }

  public serializedValueGuarded(visited = new WeakSet<any>()): any {
    if (visited.has(this)) return {};
    visited.add(this);

    return this.value;
  }

  public get typedValue() {
    return this.value;
  }

  public typedValueGuarded(visited = new WeakSet<any>()): any {
    if (visited.has(this)) return {};
    visited.add(this);

    return this.typedValue;
  }

  public get unsavedValue() {
    return this.hasUnsavedChanges ? this.value : undefined;
  }

  public get serializedChangesValue() {
    return this.serializedChangesValueGuarded();
  }

  public serializedChangesValueGuarded(visited = new WeakSet<any>()): any {
    return this.hasUnsavedChanges
      ? this.serializedValueGuarded(visited)
      : undefined;
  }

  public get serializedPayload() {
    if (typeof this.serializedValue === "undefined" || this.isRemotelyComputed)
      return;
    return { [this.serializedName]: this.serializedValue };
  }

  public get serializedChangesPayload() {
    if (this.isRemotelyComputed || !this.isHydrated || !this.hasUnsavedChanges)
      return;

    return {
      [this.serializedChangesName]: this.serializedChangesValue,
    };
  }

  public serializedChangesPayloadGuarded(visited = new WeakSet<any>()) {
    if (this.isRemotelyComputed || !this.isHydrated || !this.hasUnsavedChanges)
      return;

    return {
      [this.serializedChangesName]: this.serializedChangesValueGuarded(visited),
    };
  }

  public get typedPayload() {
    return this.typedValueGuarded();
  }

  public typedPayloadGuarded(
    visited = new WeakSet<any>()
  ): Record<string, any> {
    return {
      [this.typedName]: this.typedValueGuarded(visited),
    };
  }

  public get stringValue(): string {
    return this.value ? this.value : "-";
  }

  public get isValid() {
    return Rule.isValid(this.rules, this.value);
  }

  public get isRequired(): boolean {
    return Rule.rulesIncludes(this.rules, "required");
  }

  public findProperty(fieldArg: CrudPropertyQuery): CrudProperty | undefined {
    const res = this.findProperties(fieldArg);
    return res ? res.pop() : undefined;
  }
  public findProperties(
    propertyQueries?: CrudPropertyQuery[] | CrudPropertyQuery
  ): CrudProperty[] | undefined {
    if (!propertyQueries) return [this];
    if (!Array.isArray(propertyQueries)) propertyQueries = [propertyQueries];
    if (!propertyQueries[0]) return [this];

    return propertyQueries
      .filter((propertyQuery) => propertyQuery)
      .some((propertyQuery) =>
        typeof propertyQuery === "string"
          ? this.name == propertyQuery
          : propertyQuery.name == this.name
      )
      ? [this]
      : undefined;
  }

  public clone(newValue?: any) {
    const clone = new this._classRef(this._opts);
    if (typeof newValue !== "undefined") clone.set(newValue, true);
    return clone;
  }

  public clear() {
    this.set(null);
  }
}
