
import { CrudFieldSet } from "../CrudFieldSet";
import { CrudModel } from "../CrudModel";
export default {
  data() {
    return {
      fields: [],
    };
  },
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
    fieldSet: {
      type: CrudFieldSet,
      required: true,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  watch: {
    model: {
      immediate: true,
      handler() {
        this.fields =
          this.readMode || !this.model.isNew
            ? this.model.getVisibleFields(this.fieldSet.fields)
            : this.model.getEditableFields(this.fieldSet.fields);
      },
    },
  },
  computed: {
    formReadonly() {
      return (
        this.readMode || this.model.isReadonlyToUser() || this.fieldSet.readonly
      );
    },
    fieldProps() {
      return {
        "parent-read-mode": this.formReadonly,
        class: "px-3 py-1",
      };
    },
    allLabelsDetached() {
      return (
        this.$config.useDetachedFieldLabels ||
        this.readMode ||
        this.fields.every((f) => f.requiresDetachedLabel)
      );
    },
  },
};
