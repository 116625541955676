import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipField } from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class SurveyResponse extends CrudModel {
  public static icon = "mdi-comment-question-outline";
  protected static _typeLabel = "Survey Response";
  protected static asProperty = "survey_response";
  public static api = {
    path: "survey-responses",
  };

  protected userIdField = ["user"];
  protected static routeBase = "survey-responses";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        useLocaleString: false,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Submitted At",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },

    {
      type: StringProperty,
      opts: {
        name: "where_hear",
        label: "Where did you hear about the methods?",
      },
    },
    {
      type: StringProperty,
      opts: { name: "nationality", label: "Nationality" },
    },
    {
      type: StringProperty,
      opts: { name: "language", label: "Native Language" },
    },
    {
      type: StringProperty,
      opts: { name: "other_languages", label: "Other Language(s)" },
    },
    { type: StringProperty, opts: { name: "gender", label: "Gender" } },
    {
      type: StringProperty,
      opts: { name: "other_background", label: "Other_background" },
    },
    {
      type: BooleanProperty,
      opts: { name: "studio_owner", label: "Studio_owner" },
    },
    { type: BooleanProperty, opts: { name: "pilates", label: "Pilates" } },
    { type: BooleanProperty, opts: { name: "dance", label: "Dance" } },
    { type: BooleanProperty, opts: { name: "massage", label: "Massage" } },
    {
      type: BooleanProperty,
      opts: { name: "personal_trainer", label: "Personal_trainer" },
    },
    {
      type: BooleanProperty,
      opts: { name: "physical_therapist", label: "Physical_therapist" },
    },
    {
      type: BooleanProperty,
      opts: { name: "no_movement_bg", label: "No_movement_bg" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_fitness", label: "Fitness Center" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_pt", label: "Physical therapy facility" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_clinic", label: "Clinic" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_indie_studio", label: "Independent Studio" },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "where_dedicated_gyro",
        label: "Dedicated Gyrotonic studio",
      },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_mixed_studio", label: "Mixed discipline studio" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_mixed_pilates", label: "Pilates" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_mixed_yoga", label: "Yoga" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_mixed_personal", label: "Personal Training" },
    },
    {
      type: BooleanProperty,
      opts: { name: "where_mixed_massage", label: "Massage Therapy" },
    },
    {
      type: StringProperty,
      opts: { name: "studio_trainers", label: "# of trainers at your studio" },
    },
    {
      type: StringProperty,
      opts: { name: "where_mixed_other", label: "Other" },
    },
    {
      type: StringProperty,
      opts: { name: "clients_per_week", label: "Average Clients Per Week" },
    },
    {
      type: StringProperty,
      opts: {
        name: "gt_private_sessions",
        label: "Gyrotonic Private Sessions Per Week",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "gk_private_sessions",
        label: "Gyrokinesis Private Sessions Per Week",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "gt_group_sessions",
        label: "Gyrotonic Group Sessions Per Week",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "gk_group_sessions",
        label: "Gyrokinesis Group Sessions Per Week",
      },
    },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Submitted By",
        relatedModel: "User",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    { type: StringField, opts: { property: "where_hear" } },
    { type: StringField, opts: { property: "nationality" } },
    { type: StringField, opts: { property: "language" } },
    { type: StringField, opts: { property: "other_languages" } },
    { type: StringField, opts: { property: "gender" } },
    { type: StringField, opts: { property: "other_background" } },
    { type: BooleanField, opts: { property: "studio_owner" } },
    { type: BooleanField, opts: { property: "pilates" } },
    { type: BooleanField, opts: { property: "dance" } },
    { type: BooleanField, opts: { property: "massage" } },
    { type: BooleanField, opts: { property: "personal_trainer" } },
    { type: BooleanField, opts: { property: "physical_therapist" } },
    { type: BooleanField, opts: { property: "no_movement_bg" } },
    { type: BooleanField, opts: { property: "where_fitness" } },
    { type: BooleanField, opts: { property: "where_pt" } },
    { type: BooleanField, opts: { property: "where_clinic" } },
    { type: BooleanField, opts: { property: "where_indie_studio" } },
    { type: BooleanField, opts: { property: "where_dedicated_gyro" } },
    { type: BooleanField, opts: { property: "where_mixed_studio" } },
    { type: BooleanField, opts: { property: "where_mixed_pilates" } },
    { type: BooleanField, opts: { property: "where_mixed_yoga" } },
    { type: BooleanField, opts: { property: "where_mixed_personal" } },
    { type: BooleanField, opts: { property: "where_mixed_massage" } },
    { type: StringField, opts: { property: "studio_trainers" } },
    { type: StringField, opts: { property: "where_mixed_other" } },
    { type: StringField, opts: { property: "clients_per_week" } },
    { type: StringField, opts: { property: "gt_private_sessions" } },
    { type: StringField, opts: { property: "gk_private_sessions" } },
    { type: StringField, opts: { property: "gt_group_sessions" } },
    { type: StringField, opts: { property: "gk_group_sessions" } },

    // relationships
    {
      type: RelationshipField,
      opts: { property: "user", isPrimaryLabelField: 1 },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["user", "created_at"],
      filterFields: [],
      enableDynamicFilters: true,
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ];
}
