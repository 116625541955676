
export default {
  data: () => ({
    model: null,
    snapshotId: null,

    unsavedDialogOpen: false,
    navIntentTo: null,
    renderKey: 0,
  }),
  props: {
    modelType: null,
    layoutId: {
      type: String,
    },
    id: null,
    fluid: true,
  },
  methods: {
    onSave() {
      this.snapshotId = this.model.takeSnapshot();
      this.layout.navigateAfterSave();
    },
    onDelete() {
      this.layout.navigateAfterDelete();
    },
    onRevert() {
      this.model.restoreSnapshot(this.snapshotId);
      this.renderKey++;
    },
    confirmNav() {
      this.unsavedDialogOpen = false;
      this.$router.push(this.navIntentTo);
    },
    cancelNav() {
      this.navIntentTo = null;
      this.unsavedDialogOpen = false;
    },
    setupModelFromId(id) {
      if (id == "new") {
        const newModelOpts = this.$route.query;
        this.model = this.layout.newModel(newModelOpts);
        return;
      }

      this.layout.getModel(id).then((model) => {
        this.model = model;
        this.snapshotId = this.model.takeSnapshot();
      });
    },
  },
  computed: {
    title() {
      return this.model.label;
    },
    layout() {
      return this.modelType.getModelLayout(this.layoutId);
    },
  },
  watch: {
    "$route.params.id": {
      async handler() {
        if (this.id) return;

        this.setupModelFromId(this.$route.params.id);
      },
      immediate: true,
    },
    id: {
      async handler() {
        if (!this.id) return;

        this.setupModelFromId(this.id);
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.model.wasJustSaved ||
      !this.model ||
      !this.model.hasUnsavedChanges ||
      this.navIntentTo
    )
      return next();

    this.navIntentTo = to;
    this.unsavedDialogOpen = true;
  },
};
