import { CrudModel } from "../plugins/Cruxtify/CrudModel";

import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import {
  BooleanField,
  MoneyField,
  NumberField,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  BooleanProperty,
  DateProperty,
  FileProperty,
  MoneyProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  DateField,
  FileField,
  LongTextField,
  RelationshipField,
  RelationshipFieldMany,
  SelectField,
} from "../plugins/Cruxtify/field-types";

import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

export class Contract extends CrudModel {
  public static icon = "mdi-file-sign";
  protected static _typeLabel = "Contract";
  protected static asProperty = "contract";
  public static api = {
    path: "contracts",
  };

  protected userIdField = ["trainer.user"];
  protected static routeBase = "contracts";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },

    { type: DateProperty, opts: { name: "start_date", label: "Start Date" } },
    {
      type: DateProperty,
      opts: {
        name: "end_date",
        label: "Expiration Date",
        isRemotelyComputed: true,
        sortable: false,
      },
    },
    {
      type: NumberProperty,
      opts: { name: "contract_length", label: "Contract Length (months)" },
    },
    {
      type: StringProperty,
      opts: { name: "external_id", label: "Signaturit ID" },
    },
    {
      type: StringProperty,
      opts: { name: "external_doc_id", label: "Signaturit Doc ID" },
    },
    {
      type: StringProperty,
      opts: { name: "signer_name", label: "Signer Name", rules: ["required"] },
    },
    {
      type: StringProperty,
      opts: {
        name: "signer_address",
        label: "Signer Address",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "signer_email",
        label: "Signer Email",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "signer_title",
        label: "Signer Title",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "signer_country",
        label: "Signer Country",
        rules: ["required"],
      },
    },
    {
      type: DateProperty,
      opts: { name: "signed_date", label: "Signed Date" },
    },
    { type: DateProperty, opts: { name: "paid_date", label: "Paid Date" } },
    { type: MoneyProperty, opts: { name: "fee", label: "Fee" } },
    {
      type: StringProperty,
      opts: {
        name: "transaction_id",
        label: "Transaction ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "transaction_info",
        label: "Transaction Info",
        isRemotelyComputed: true,
      },
    },
    {
      type: FileProperty,
      opts: { name: "signed_pdf_s3_id", label: "Signed PDF" },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_payment_requested",
        label: "Is Payment Requested",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_perpetual",
        label: "Is Perpetual License",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: StringProperty,
      opts: { name: "status", label: "Status", isRemotelyComputed: true },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_current",
        label: "Most Current License",
        description:
          "Is this the most current license for this type of license and this country?",
        isRemotelyComputed: true,
      },
    },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "previous_contract",
        label: "Previous Contract",
        relatedModel: "Contract",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "trainer",
        label: "Trainer",
        relatedModel: "Trainer",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "country",
        label: "Contract Country",
        relatedModel: "Country",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract_type",
        label: "Contract Type",
        relatedModel: "ContractType",
        rules: ["required"],
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notes",
        label: "Notes",
        relatedModel: "Note",
        foreignProperty: "contract",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notifications",
        label: "Notifications",
        relatedModel: "Notification",
        foreignProperty: "contract",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "contract_events",
        label: "History",
        relatedModel: "ContractEvent",
        foreignProperty: "contract",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
        isRemotelyComputed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        relatedModel: "User",
        userPermissions: UserPermission.Hidden,
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: DateField,
      opts: {
        property: "start_date",
        conditional: {
          property: "is_perpetual",
          compare: "notEquals",
          value: 1,
        },
      },
    },
    {
      type: NumberField,
      opts: {
        property: "contract_length",
        conditional: {
          property: "is_perpetual",
          compare: "notEquals",
          value: 1,
        },
      },
    },
    {
      type: StringField,
      opts: { property: "external_id" },
    },
    {
      type: StringField,
      opts: { property: "external_doc_id" },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "contract_events",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: StringField,
      opts: { property: "signer_name", isPrimaryLabelField: 1 },
    },
    {
      type: StringField,
      opts: { property: "signer_address", md: 6, sm: 3 },
    },
    {
      type: StringField,
      opts: { property: "signer_email" },
    },
    {
      type: StringField,
      opts: { property: "signer_title" },
    },
    {
      type: StringField,
      opts: { property: "signer_country" },
    },
    {
      type: DateField,
      opts: { property: "signed_date" },
    },
    {
      type: DateField,
      opts: {
        property: "end_date",
        conditional: {
          property: "is_perpetual",
          compare: "notEquals",
          value: 1,
        },
      },
    },
    { type: DateField, opts: { property: "paid_date" } },
    { type: MoneyField, opts: { property: "fee" } },
    {
      type: StringField,
      opts: { property: "transaction_id" },
    },
    {
      type: LongTextField,
      opts: { property: "transaction_info" },
    },
    {
      type: FileField,
      opts: { property: "signed_pdf_s3_id" },
    },
    {
      type: BooleanField,
      opts: { property: "is_payment_requested" },
    },
    {
      type: BooleanField,
      opts: { property: "is_perpetual" },
    },
    {
      type: SelectField,
      opts: {
        property: "status",
        options: {
          preparingContract: "Preparing Contract",
          waitingPayment: "Awaiting Payment",
          expired: "Expired",
          active: "Active",
          waitingSigning: "Awaiting Signature",
        },
      },
    },
    {
      type: StringField,
      opts: {
        id: "action",
        property: "status",
        label: "",
        viewComponentName: "contract-user-action",
        formComponentName: "contract-user-action",
      },
    },

    // relationships
    {
      type: RelationshipField,
      opts: { property: "previous_contract" },
    },
    {
      type: RelationshipField,
      opts: { property: "trainer" },
    },
    {
      type: RelationshipField,
      opts: { property: "user" },
    },
    {
      type: RelationshipField,
      opts: {
        property: "country",
        isPrimaryLabelField: 3,
        conditional: {
          property: "is_perpetual",
          compare: "notEquals",
          value: 1,
        },
      },
    },
    {
      type: RelationshipField,
      opts: { property: "contract_type", isPrimaryLabelField: 2 },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notes",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "admin",
      inlineEdit: false,
      useModal: true,
      columnFields: [
        "updated_at",
        "contract_type",
        "trainer",
        "country",
        "start_date",
        "end_date",
        "status",
      ],
      collectionActionComponents: ["collection-export"],
      enableDynamicFilters: true,
      defaultSort: [
        {
          field: "updated_at",
          order: "desc",
        },
      ],
      rowClassProperties: ["status"],
    },
    {
      id: "admin_field",
      inlineEdit: false,
      useModal: true,
      groupBy: "contract_type.method_type",
      columnFields: [
        "updated_at",
        "contract_type",
        "trainer",
        "country",
        "start_date",
        "end_date",
        "status",
      ],
      collectionActionComponents: ["collection-export"],
      enableDynamicFilters: true,
      defaultSort: [
        {
          field: "start_date",
          order: "desc",
        },
      ],
      rowClassProperties: ["status"],
    },
    {
      id: "trainer",
      disableRowClick: true,
      columnFields: [
        "contract_type",
        "country",
        "start_date",
        "end_date",
        "status",
        "action",
      ],
      enableDynamicFilters: false,
      defaultSort: [
        {
          field: "start_date",
          order: "desc",
        },
      ],
    },
  ]);

  protected static modelLayoutDefinitions = [
    {
      id: "admin",
      headerComponents: [
        "ContractSignature",
        "ContractPayment",
        "ContractDownload",
      ],
      navigateAfterSave: false,
      modelRequestOptions: {
        populate: ["contract_events"],
      },
      fieldSets: [
        {
          id: "events",
          fields: ["status", "contract_events", "notes", "notifications"],
        },
        {
          id: "id",
          fields: [
            "trainer",
            "contract_type",
            "is_perpetual",
            "country",
            "start_date",
            "end_date",
            "contract_length",
            "previous_contract",
          ],
        },
        {
          id: "signer",
          fields: [
            "signer_name",
            "signer_address",
            "signer_country",
            "signer_email",
            "signer_title",
          ],
        },
        {
          id: "signed",
          fields: ["signed_date", "signed_pdf_s3_id"],
        },
        {
          id: "payment",
          fields: [
            "is_payment_requested",
            "fee",
            "paid_date",
            "transaction_info",
            "transaction_id",
          ],
        },
        {
          id: "meta",
          fields: ["id", "created_at", "updated_at"],
        },
      ],
    },
  ];

  public sendSignatureRequest() {
    return this.$nuxt.$api
      .POST("contracts/" + this.id + "/send-request")
      .then((res) => {
        if (res.data) return res.data;

        return res;
      });
  }

  public sendSignatureRequestReminder() {
    return this.$nuxt.$api
      .POST("contracts/" + this.id + "/send-request-reminder")
      .then((res) => {
        if (res.data) return res.data;

        return res;
      });
  }

  public cancelSignatureRequest() {
    return this.$nuxt.$api
      .POST("contracts/" + this.id + "/cancel-request")
      .then((res) => {
        if (res.data) return res.data;

        return res;
      });
  }

  public sendPaymentRequest() {
    return this.$nuxt.$api
      .POST("contracts/" + this.id + "/send-payment-request")
      .then((res) => {
        if (res.data) return res.data;

        return res;
      });
  }

  public static get stripeReturnUrl() {
    return (
      this.$nuxt.$config.apiURL + "/api/contracts/stripe/payment-intent-result"
    );
  }

  public static getPaymentIntent(contractId) {
    return this.$nuxt.$api
      .GET("contracts/" + contractId + "/stripe/payment-intent")
      .then((res) => {
        if (res.data) return res.data.intent;

        return res.intent;
      });
  }
}
